<template>
    <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('tpm_report.training_material_report') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="fiscal_year_id"
              >
                <template v-slot:label>
                  {{$t('elearning_config.fiscal_year')}}
                </template>
                <v-select name="organization"
                  v-model="search.fiscal_year_id"
                  label="text"
                  :reduce="item => item.value"
                  :options= fiscalYearList
                  :placeholder="$t('globalTrans.select')"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="organization"
                  :label="$t('tpm_report.circular_memo_no')"
              >
                  <v-select name="circular_memo_no"
                    v-model="search.circular_memo_no"
                    label="text"
                    :reduce="item => item.value"
                    :options= circularList
                    :placeholder="$t('globalTrans.select')"
                  />
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('elearning_config.organization')"
                  label-for="organization"
              >
                <v-select name="organization"
                    v-model="search.orgg_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= orgList
                    :placeholder="$t('globalTrans.select')"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="training_title_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.training_title')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.training_title_id"
                    :options="trainingTitleList"
                    id="training_title_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="batch_id"
                >
                  <template v-slot:label>
                    {{$t('elearning_config.batch')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.batch_id"
                    :options="batchList"
                    id="batch_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
              </b-form-group>
            </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" sm="12" class="text-right">
                <b-button size="sm" type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
        </b-row>
        </template>
      </card>
      <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay>
            <b-row>
              <b-col md="12">
                <body-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('tpm_report.training_material_report') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <b-button class="btn_add_new" @click="pdfExport">
                      <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                    </b-button>
                  </template>
                  <template v-slot:body>
                    <b-overlay :show="loading">
                      <b-row mt-5>
                        <b-col md="12" class="table-responsive" >
                          <div style="border: 2px solid;margin:10px;padding:10px">
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="search.orgg_id ? search.orgg_id : 10">
                                  <template v-slot:projectNameSlot>
                                    {{ }}
                                  </template>
                                  {{ $t('tpm_report.training_material_report') }}
                                </list-report-head>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <div class="text-center">
                                  <table style="width:100%;color:black;">
                                    <tr v-if="search.fiscal_year">
                                        <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                    </tr>
                                    <tr v-if="search.training_quarter_id">
                                        <td align="right" style="width:45%">{{ $t('elearning_config.training_quarter_name') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.quaeter_name_bn : search.quaeter_name }}</td>
                                    </tr>
                                    <tr v-if="search.circular_memo_no">
                                        <td align="right" style="width:45%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ search.circular_memo_no }}</td>
                                    </tr>
                                    <tr v-if="search.course_id">
                                        <td align="right" style="width:45%">{{ $t('globalTrans.course') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.course_name_bn : search.course_name }}</td>
                                    </tr>
                                    <tr v-if="search.start_date">
                                        <td align="right" style="width:45%">{{ $t('globalTrans.start_date') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ search.start_date | dateFormat }}</td>
                                    </tr>
                                    <tr v-if="search.end_date">
                                        <td align="right" style="width:45%">{{ $t('globalTrans.end_date') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ search.end_date | dateFormat }}</td>
                                    </tr>
                                  </table>
                                </div>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                                  <b-thead>
                                    <b-tr>
                                      <b-th style="width:8%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                      <b-th style="width:13%" class="text-center">{{ $t('globalTrans.organization') }}</b-th>
                                      <b-th style="width:12%" class="text-center">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                      <b-th class="text-center">{{$t('elearning_config.training_title')}}</b-th>
                                      <b-th class="text-center">{{$t('tpm_report.material_name')}}</b-th>
                                      <b-th style="width:10%" class="text-center">{{ $t('globalTrans.quantity') }}</b-th>
                                      <b-th style="width:6%" class="text-center">{{$t('tpm_report.cost')}}</b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(info, index) in datas" :key="index" >
                                      <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.org_bn : info.org }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.fiscal_year_bn : info.fiscal_year }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.training_title_bn : info.training_title }}</b-td>
                                      <b-td class="text-center">
                                        <span v-for="(infos, indexx) in datas[index].details" :key="indexx"> {{ ++indexx }}. {{ ($i18n.locale==='bn') ? infos.material_name_bn : infos.material_name }}<br></span>
                                      </b-td>
                                      <b-td class="text-center">
                                        <span v-for="(infos, indexx) in datas[index].details" :key="indexx" :i="0">{{ ++indexx }}. {{ $n(infos.quantity ) }}<br></span>
                                      </b-td>
                                      <b-td class="text-center">
                                        <span v-for="(infos, indexx) in datas[index].details" :key="indexx" :i="0">{{ ++indexx }}. {{ $n(infos.total_price ) }}<br></span>
                                      </b-td>
                                    </b-tr>
                                    <b-tr v-if="datas.length === 0">
                                      <th colspan="7" class="text-center">{{ $t('globalTrans.no_data_found') }}</th>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </body-card>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
      </b-row>
    </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingMaterialReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    data () {
        return {
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          search: {
            fiscal_year_id: this.$store.state.currentFiscalYearId,
            limit: 10,
            orgg_id: [],
            circular_memo_no: '',
            batch_id: 0,
            training_title_id: 0
          },
          circularLoadings: 0,
          circularLoading: 0,
          circularList: [],
          batchList: [],
          officeTypeList: [],
          officeList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          courseList: [],
          userList: [],
          circularMemoList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          loading: false,
          showData: false,
          currentFiscalYearId: 0
        }
    },
    created () {
      this.getCircularList()
    },
    mounted () {
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      i18 () {
        return this.$i18n.locale
      },
      currentLocal () {
      return this.$i18n.locale
      },
      trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      }
    },
    watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.fiscal_year_id': function (newValue) {
      this.getCircularList(newValue)
    },
    'search.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'search.orgg_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
      this.batchList = this.getBatchList(newValue)
    }
    },
  methods: {
      getTrainingTitleList (orgId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (orgId) {
        return trainingTitleList.filter(item => item.org_id === orgId)
      }
      return trainingTitleList
      },
     getBatchList (orgId) {
      const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
      if (orgId) {
        return batchList.filter(item => item.org_id === orgId)
      }
      return batchList
      },
      async pdfExport () {
        this.loading = true
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 12, search: this.search })
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainingMaterialReport, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.loading = false
      },
      async getCircularList () {
        this.circularLoadings = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/circular-publication-list' + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoadings = false
      },
      async getCircularMemoNo (getCircularMemoNo) {
            this.circularLoading = true
            if (getCircularMemoNo) {
              this.load = true
              const params = {
                circular_memo_no: getCircularMemoNo,
                table: 'iab_circular_publications'
              }
              this.trainerListData = []
              const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/tpm/training-schedule/circular-wise-trainer', params)
              if (!result.success) {
                this.search.orgg_id = 0
                this.search.training_title_id = 0
              } else {
                this.lcErrorMsg = ''
                const trData = result.data
                this.search.orgg_id = trData.org_id
                this.search.training_title_id = trData.training_title_id
                const tmpList = result.aryRange.map((obj, index) => {
                  return { value: obj, text: obj }
                })
                this.selectDate = tmpList
                // this.trainerListData = result.trainerList
              }
              this.load = false
              this.circularLoading = false
            }
          },
      onChangeFile (e) {
          this.attachmentDemo = e.target.files[0]
      },
      getCourseList (circularMemoNo) {
        const courseList = this.$store.state.TrainingElearning.commonObj.courseList
        if (circularMemoNo) {
          return courseList.filter(item => item.circular_memo_no === String(circularMemoNo))
        }
        return courseList
      },
      getEditingData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      },
      async searchData () {
        this.loading = true
        this.showData = true
        // this.getCustomDataSearch()
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingMaterialReport, this.search)
        if (result.success) {
          this.loading = false
          if (result.data) {
            this.datas = this.getCustomDataList(result.data)
          }
        } else {
          this.datas = []
          this.loading = false
        }
      },
      getCustomDataSearch () {
        // const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
        // if (typeof fiscalYearObj !== 'undefined') {
        //   this.search.fiscal_year = fiscalYearObj.text_en
        //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
        // //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
        // } else {
        //   this.search.fiscal_year = ''
        //   this.search.fiscal_year_bn = ''
        // }
        // const tQuarterObj = this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.find(doc => doc.value === parseInt(this.search.training_quarter_id))
        // if (typeof tQuarterObj !== 'undefined') {
        //     this.search.quaeter_name = tQuarterObj.text_en
        //     this.search.quaeter_name_bn = tQuarterObj.text_bn
        // } else {
        //     this.search.quaeter_name = ''
        //     this.search.quaeter_name_bn = ''
        // }
        // const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(this.search.course_id))
        // if (typeof courseObj !== 'undefined') {
        //     this.search.course_name = courseObj.text_en
        //     this.search.course_name_bn = courseObj.text_bn
        // } else {
        //     this.search.course_name = ''
        //     this.search.course_name_bn = ''
        // }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }

          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          return Object.assign({}, item, orgData, fiscalYearData, trainingTitleData, trainingTypeData)
        })
        return listData
      }
    }
}
</script>
